<template>
  <q-layout view="hHh lpr fff">
    <q-header bordered>
      <q-toolbar class="bg-secondary text-weight-bolder">
        <q-btn
          v-if="$q.platform.is.mobile"
          flat
          dense
          round
          aria-label="Menu"
          @click="leftDrawerOpen = !leftDrawerOpen"
          icon="menu"
        />
        <q-toolbar-title>
          <Logo />
        </q-toolbar-title>
        <q-btn-dropdown
          flat
          dense
          icon="person"
          aria-label="Sair"
        >
          <div class="row no-wrap q-pa-md">
            <div class="column items-center">
              <q-avatar size="72px">
                <q-icon name="face" />
              </q-avatar>

              <div v-if="user">
                <div class="text-subtitle1 q-mt-md q-mb-xs">{{user.user_metadata.name}}</div>
                <q-separator />
                <q-btn
                  flat
                  icon="logout"
                  label="Sair"
                  size="sm"
                  v-close-popup
                  @click="handleLogout"
                />

              </div>
              <div
                v-else
                class="q-gutter-y-md"
              >
                <q-btn
                  flat
                  icon="person"
                  label="Entrar"
                  size="sm"
                  v-close-popup
                  :to="{name: 'login'}"
                />
                <q-separator />
                <q-btn
                  flat
                  icon="person_add"
                  label="Cadastrar"
                  size="sm"
                  v-close-popup
                  :to="{name: 'register'}"
                />
              </div>
            </div>
          </div>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      class="bg-grey-1"
      side="left"
      :mini="miniState"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
      :width="300"
      :breakpoint="500"
    >
      <MainMenu :itens="linksList" />
    </q-drawer>

    <q-drawer
      show-if-above
      v-model="rightDrawerOpen"
      side="right"
    >
      <!-- drawer content -->
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
    <q-footer
      class=" text-grey-9"
      style=" padding-top: 6px !important; padding-bottom: 6px !important; -webkit-backdrop-filter: blur(7px) !important; backdrop-filter: blur(7px) !important; background-color: #fafafa1a !important;"
    >
      <q-toolbar>
        <q-toolbar-title>
          <div class="text-body2 text-center">Power by <b>Abraão Pinto</b></div>
        </q-toolbar-title>
        <div class="text-body2">©2022 v{{ appVersion }}</div>
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<script>
import { defineComponent, ref, defineAsyncComponent } from 'vue'
import useAuthUser from '../composables/UseAuthUser'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import useNotify from '../composables/UseNotify'

const linksList = [
  {
    title: 'Home',
    caption: '',
    icon: 'home',
    routeName: 'home'
  },
  {
    title: 'Tema',
    caption: '',
    icon: 'topic',
    routeName: 'theme'
  },
  {
    title: 'Categorias',
    caption: '',
    icon: 'category',
    routeName: 'category'
  },
  {
    title: 'Autores',
    caption: '',
    icon: 'groups',
    routeName: 'author'
  },
  {
    title: 'Frases',
    caption: '',
    icon: 'notes',
    routeName: 'phrase'
  },
  {
    title: 'Sobre',
    caption: '',
    icon: 'info',
    routeName: 'about'
  }
]

export default defineComponent({
  name: 'MainLayout',
  components: {
    MainMenu: defineAsyncComponent(() =>
      import('./MainMenu')),
    Logo: defineAsyncComponent(() =>
      import('@/components/Logo'))
  },
  computed: {
    appVersion () {
      return process.env.VUE_APP_VERSION
    }
  },
  setup () {
    const { logout, user } = useAuthUser()
    const router = useRouter()
    const $q = useQuasar()
    const { notifyError, notifySuccess } = useNotify()

    const handleLogout = async () => {
      $q.dialog({
        title: 'Confirme',
        message: 'Você realmente quer sair?',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        try {
          await logout()
          notifySuccess('Logout realizado com sucesso!')
          router.replace({ name: 'home' })
        } catch (error) {
          notifyError(error.message)
        }
      })
    }

    return {
      leftDrawerOpen: ref(false),
      rightDrawerOpen: ref(false),
      miniState: ref(true),
      textSearch: ref(''),
      user,
      linksList,
      handleLogout
    }
  }
})
</script>
<style scoped>
/* Centralizar logo no mobile */
body.mobile .q-toolbar__title {
  text-align: center;
}
</style>
